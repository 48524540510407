<template>
  <div class="card widget widget-chat" style="width: 100%">
    <div class="card-header">
      <h5 class="card-title">{{ $t("vehicle_notes.title") }}</h5>
    </div>
    <div
      class="widget-chat-messages-container"
      :style="'max-height: ' + maxHeight + 'px'"
    >
      <div
        v-for="(note, index) in reverseNotes"
        :key="'note-' + index"
        class="widget-chat-message-item"
        :class="{ own: note.user.email === $store.state.auth.user.email }"
      >
        <div class="widget-chat-message-item-content">
          <div class="widget-chat-message-item-text">
            {{ note.note }}
          </div>
          <p class="text-muted note-user">
            {{ note.user.email }} {{ $t("vehicle_notes.on") }}
            <timestamp :time="note.created_at" format="dd/MM/yyyy" />
            {{ $t("vehicle_notes.at") }}
            <timestamp :time="note.created_at" format="HH:mm:ss" />
          </p>
        </div>
      </div>
    </div>
    <div class="widget-chat-compose">
      <input
        @keyup.enter="createNote"
        class="widget-chat-compose-input"
        v-model="note"
        type="text"
        :placeholder="$t('global.type')"
      />
      <button class="widget-chat-compose-send-btn" @click="createNote">
        <i class="material-icons" v-if="!submitting">arrow_forward_ios</i>
        <div v-else class="spinner-border text-primary" role="status">
          <span class="visually-hidden">{{ $t("global.loading") }}...</span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import VehicleService from "../../services/vehicle.service";
import Timestamp from "../../components/Timestamp.vue";
import { DateTime } from "luxon";
export default {
  name: "VehicleNotes",
  components: {
    Timestamp,
  },
  props: {
    vehicle: Object,
  },
  data() {
    return {
      notes: [],
      loading: true,
      note: "",
      submitting: false,
    };
  },
  mounted() {
    this.loadNotes();
  },
  methods: {
    loadNotes() {
      this.loading = true;
      VehicleService.getVehicleNotes(
        this.$store.state.auth.user.selectedClient,
        this.vehicle.id
      )
        .then((response) => {
          this.notes = response.data.notes;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createNote() {
      if (this.submitting) return;
      this.submitting = true;
      VehicleService.putVehicleNote(
        this.$store.state.auth.user.selectedClient,
        this.vehicle.id,
        this.note
      )
        .then((response) => {
          this.notes.push({
            created_at: DateTime.local().toSeconds(),
            note: this.note,
            user: {
              id: this.$store.state.auth.user.id,
              email: this.$store.state.auth.user.email,
            },
            user_id: this.$store.state.auth.user.id,
            vehicle_id: this.vehicle.id,
          });
          this.note = "";
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
  computed: {
    reverseNotes: function () {
      return this.notes.sort((a, b) => {
        return b.created_at - a.created_at;
      });
    },
    maxHeight: function () {
      let element = document.getElementById("event_image");
      if (element) {
        return element.offsetHeight;
      }
      return 500;
    },
  },
};
</script>

<style scoped>
.note-user {
  font-size: 12px;
  opacity: 0.75;
}
</style>
