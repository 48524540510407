<template>
  <div>
    <!-- Loading -->
    <div v-show="imageLoading" class="parent-div" style="width: 100%">
      <div
        class="spinner-border center-div"
        style="height: 4rem; width: 4rem"
        role="status"
      >
        <span class="visually-hidden">{{ $t("events.loading") }}...</span>
      </div>
    </div>

    <!-- Image -->
    <img
      :id="id"
      ref="img"
      v-if="src !== null && !imageLoading && viewer"
      :src="src"
      @click="$emit('click')"
      v-viewer
    />
    <img
      :id="id"
      ref="img"
      v-else-if="src !== null && !imageLoading"
      :src="src"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
import axios from "axios";
import authHeader from "../services/auth-header";
export default {
  name: "EventImage",
  emits: ["loaded", "click"],
  props: {
    filename: String,
    site_id: String,
    id: String,
    storage: String | Number,
    thumb: {
      default: true,
      required: false,
      type: Boolean,
    },
    viewer: {
      default: true,
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      src: null,
      imageLoading: false,
      img: null,
      glass: null,
      bw: 3,
      w: 0,
      h: 0,
    };
  },
  mounted() {
    this.imageLoading = true;
    return axios
      .get(
        `https://api.varsanpr.com/api/event/${
          this.thumb ? "thumbnail" : "image"
        }/${this.filename}?site_id=${this.site_id}&storage_account=${
          this.storage
        }`,
        {
          responseType: "arraybuffer",
          headers: authHeader(),
        }
      )
      .then((response) => {
        this.src = `data:image/jpeg;base64,${Buffer.from(
          response.data,
          "binary"
        ).toString("base64")}`;
        this.imageLoading = false;
        this.$emit("loaded");
      })
      .catch((error) => {
        this.imageLoading = false;
        return null;
      });
  },
  methods: {
    loadImage() {
      this.imageLoading = true;
      return axios
        .get(
          `https://api.varsanpr.com/api/event/${
            this.thumb ? "thumbnail" : "image"
          }/${this.filename}?site_id=${this.site_id}&storage_account=${
            this.storage
          }`,
          {
            responseType: "arraybuffer",
            headers: authHeader(),
          }
        )
        .then((response) => {
          this.src = `data:image/jpeg;base64,${Buffer.from(
            response.data,
            "binary"
          ).toString("base64")}`;
          this.imageLoading = false;
        })
        .catch((error) => {
          this.imageLoading = false;
          return null;
        });
    },
  },
  watch: {
    filename: function (val) {
      this.loadImage();
    },
    site_id: function (val) {
      this.loadImage();
    },
    endpoint: function (val) {
      this.loadImage();
    },
    thumb: function (val) {
      this.loadImage();
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
</style>
